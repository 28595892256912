import { StateCreator } from "zustand";

export interface FeedbackSlice {
  feedbackTitle: string;
  feedbackCompanyName: string;
  feedbackQuestions: Question[][];
  feedbackResponse: any[]; // Add feedbackResponse property
  feedbackFilters: Question[][];
  feedbackFilterResponse: any[]; // Add feedbackFilterResponse property
  setFeedbackTitle: (feedbackTitle: string) => void;
  setFeedbackCompanyName: (feedbackCompanyName: string) => void;
  updateFeedbackData: (data: any) => void;
  addFeedbackResponse: (
    response: any,
    groupIndex: number,
    questionIndex: number
  ) => void; // Add step parameter

  addFeedbackFilterResponse: (
    response: any,
    groupIndex: number,
    questionIndex: number
  ) => void;

  resetFeedback: () => void;
}

const initialState = {
  feedbackTitle: "",
  feedbackCompanyName: "",
  feedbackQuestions: [],
  feedbackFilters: [],
  feedbackResponse: [],
  feedbackFilterResponse: [],
};

interface Question {
  id: string;
  question: string;
  question_order: number;
  type: string;
  options: Option[];
}

interface Option {
  id: string;
  option: string;
  questionId: string;
  createdAt: string;
  updatedAt: string;
}

function processFilters(data: any): Question[][] {
  const allQuestions = data.survey?.questions;
  const sortedQuestions = allQuestions.sort(
    (a, b) => a.question_order - b.question_order
  );

  // Group questions by question_order
  const groupedQuestions: Question[][] = [];
  let currentOrder = -1;

  sortedQuestions.forEach((question) => {
    if (question.question_order !== currentOrder) {
      currentOrder = question.question_order;
      groupedQuestions.push([]);
    }
    groupedQuestions[groupedQuestions.length - 1].push(question);
  });
  return groupedQuestions;
}

function processQuestions(data: any, created_by: string): Question[][] {
  // Concatenate baseline questions and additional questions
  const allQuestions = data.BaselineQuestion?.concat(data.AdditionalQuestion)
    .map((question: any) => ({
      ...question.Question,
    }))
    .map((question: any) => ({
      ...question,
      question: question.question.replace("[Company]", created_by),
      question_es: question.question_es.replace("[Company]", created_by),
      question_hi: question.question_hi.replace("[Company]", created_by),
    }));

  // Sort questions by question_order
  const sortedQuestions = allQuestions.sort(
    (a, b) => a.question_order - b.question_order
  );

  // Group questions by question_order
  const groupedQuestions: Question[][] = [];
  let currentOrder = -1;

  sortedQuestions.forEach((question) => {
    if (question.question_order !== currentOrder) {
      currentOrder = question.question_order;
      groupedQuestions.push([]);
    }
    groupedQuestions[groupedQuestions.length - 1].push(question);
  });
  return groupedQuestions;
}

const createFeedbackSlice: StateCreator<FeedbackSlice> = (set) => ({
  ...initialState,
  setFeedbackTitle: (feedbackTitle) => set(() => ({ feedbackTitle })),
  setFeedbackCompanyName: (feedbackCompanyName) =>
    set(() => ({ feedbackCompanyName })),
  updateFeedbackData: (data) =>
    set(() => ({
      ...initialState,
      feedbackTitle: data.data.SurveyInstanceInfo?.responder_title,
      feedbackCompanyName: data.created_by,
      feedbackQuestions: processQuestions(data.data, data.created_by),
      feedbackFilters: processFilters(data.data),
    })),
  addFeedbackResponse: (response, groupIndex, questionIndex) =>
    set((state) => {
      const newResponses = [...state.feedbackResponse];
      if (!newResponses[groupIndex]) {
        newResponses[groupIndex] = [];
      }
      newResponses[groupIndex][questionIndex] = response;
      return { feedbackResponse: newResponses };
    }),
  addFeedbackFilterResponse: (response, groupIndex, questionIndex) =>
    set((state) => {
      const newResponses = [...state.feedbackFilterResponse];
      if (!newResponses[groupIndex]) {
        newResponses[groupIndex] = [];
      }
      newResponses[groupIndex][questionIndex] = response;
      return { feedbackFilterResponse: newResponses };
    }),
  resetFeedback: () => set(() => initialState),
});

export default createFeedbackSlice;
